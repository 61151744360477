//created by kris 2021/8/5
//any class name in the project should start with yph
@import  '../../base.less';


.yph-login-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: #FFF;
    // background-image: url("../../assets/images/yphbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    &-head {
        width: 100%;
        height: 40px;
        line-height: 44px;
        text-align: right;
    }

    &-content {
        width: 100%;
        padding: 5% 0 24px;
        flex: 1 1 0;

        &-qr-sub {
            color: @primary-color;
            margin-top: 24px;
            text-align: center;
        }

        &-description {
            text-align: center;
            color: @font-color-sub;
            margin: 20px auto 42px auto;

        }


        &-title {
            text-align: center;
            height: 54px;
            margin-bottom: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;

            &-main {
                font-size: 32px;
                color: #000;
            }
        }



        &-form {
            &-subscription {
                width: 100%;
                display: flex;
                justify-content: space-between;
                color: @primary-color;
                margin-bottom: 24px;

            }
        }

        &-title,
        &-form {
            width: 328px;
            margin: 0 auto;
        }




    }


}
@primary-color: #116CE9;