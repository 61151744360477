@import (reference) '~antd/dist/antd.less'; //使用Less文件但不输出

@font-color-sub: rgba(0, 0, 0, .45);

@grey-3: #333;
@grey-6: #666;
@grey-9: #999;
@grey-c: #ccc;

@font-sm: 12px;
@font-normal: 14px;
@font-lg: 16px;

@page-content-mid-width: 960px;
@page-padding-top: 36px;

@box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);

:root {
    --primary-color: @primary-color;
}

.yph-app-page {
    &-body {
        padding: 12px;

    }
}


.yph-app-page-head {
    background-color: #fff;
    padding-left: 36px;
    padding-right: 36px;


    .content {
        display: flex;

    }
}

.yph-card-white {
    background-color: #e9ebed;

}

.yph-page-padding-content {
    padding-top: @page-padding-top;
}

.yph-page-content-mid {
    width: 960px;
    margin: 0 auto;
}

.yph-page-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: #FAFAFA;
}